import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const PostCard = props => {
  const { title, slug, summary, category } = props

  return (
      <div className="bg-white dark:bg-gray-dark h-full shadow-lg rounded-2xl overflow-hidden transform hover:scale-102 transition duration-200 ease-in-out group">
        <Link to={`/blog/${slug}/`}>
          <div className="p-4 sm:p-5">
            {category && (
              <p className="text-sm text-gray-aaa_dark dark:text-gray-aaa_light lg:text-base uppercase">{category}</p>
            )}
            <h1 className="text-xl sm:text-2xl mb-4">{title}</h1>
            <p className="text-sm sm:text-base">{summary}</p>
          </div>
        </Link>
      </div>
    );
}

PostCard.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  category: PropTypes.string,
}

export default PostCard

export const query = graphql`fragment PostCard on ContentfulPost {
  id
  title
  slug
  summary
  category
}
`
