import { Link } from "gatsby"
import React from "react"
import { FiChevronRight } from "react-icons/fi"


const Breadcrumbs = ({ name, link }) => {

  return (
    <nav className="breadcrumbs flex space-x-0.5 mb-3 items-center text-gray">
      <Link
        className="inline-block md:text-lg uppercase text-gray-dark dark:text-gray hover:text-black dark:hover:text-white"
        to={link}
      >
        {name}
      </Link>
      <FiChevronRight className="h-4 w-4" />
    </nav>
  )
}

export default Breadcrumbs
