exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cv-product-designer-js": () => import("./../../../src/pages/cv-product-designer.js" /* webpackChunkName: "component---src-pages-cv-product-designer-js" */),
  "component---src-pages-cv-ux-designer-js": () => import("./../../../src/pages/cv-ux-designer.js" /* webpackChunkName: "component---src-pages-cv-ux-designer-js" */),
  "component---src-pages-cv-ux-ui-designer-js": () => import("./../../../src/pages/cv-ux-ui-designer.js" /* webpackChunkName: "component---src-pages-cv-ux-ui-designer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sustainability-js": () => import("./../../../src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */),
  "component---src-pages-tests-form-success-js": () => import("./../../../src/pages/tests/form-success.js" /* webpackChunkName: "component---src-pages-tests-form-success-js" */),
  "component---src-pages-tests-form-test-js": () => import("./../../../src/pages/tests/form-test.js" /* webpackChunkName: "component---src-pages-tests-form-test-js" */),
  "component---src-pages-tests-logo-anim-test-js": () => import("./../../../src/pages/tests/logo-anim-test.js" /* webpackChunkName: "component---src-pages-tests-logo-anim-test-js" */),
  "component---src-pages-tests-svg-test-js": () => import("./../../../src/pages/tests/svg-test.js" /* webpackChunkName: "component---src-pages-tests-svg-test-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-archive-item-jsx": () => import("./../../../src/templates/archive-item.jsx" /* webpackChunkName: "component---src-templates-archive-item-jsx" */),
  "component---src-templates-portfolio-item-jsx": () => import("./../../../src/templates/portfolio-item.jsx" /* webpackChunkName: "component---src-templates-portfolio-item-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-private-portfolio-jsx": () => import("./../../../src/templates/private-portfolio.jsx" /* webpackChunkName: "component---src-templates-private-portfolio-jsx" */)
}

