import PropTypes from "prop-types"
import React from "react"
import ArchiveCard from "./ArchiveCard"

const Grid = ({ items }) => {
  return (
    <div className="flex flex-wrap justify-center sm:justify-start -mx-3 lg:-mx-8">
      {items.map((item) => (
        <div
          className="w-80 xs:w-1/2 md:w-1/3 lg:w-1/4 p-3"
          key={item.id}
        >
          <ArchiveCard {...item} />
        </div>
      ))}
    </div>
  )
}

Grid.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Grid
