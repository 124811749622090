import React from "react"
import { motion, useAnimation } from "framer-motion"

const WaveEmoji = () => {
  const controls = useAnimation()
  controls.start("waveStart")
  const variants = {
    waveStart: i => ({
      rotate: [0, 10, -10, 10, -10, 0],
      transition: {
        duration: 1.5,
        delay: 1.0
      }
    }),
    wave: i => ({
      rotate: [0, 10, -10, 10, -10, 0],
      transition: {
        duration: 1.5,
        delay: 0
      }
    }),

    hidden: { opacity: 0 },
  }
  return (
    <motion.div className="inline-block origin-bottom-right cursor-pointer" animate={controls} onTap={() => controls.start("wave")} variants={variants} title="tap me"><span role="img" aria-label="waving hand">
      👋
    </span></motion.div>
  )
}

export default WaveEmoji
