import PropTypes from "prop-types"
import React from "react"
//import "typeface-inter"
import "../styles/style.css"
import Header from "../components/Header"
import Tracker from "../components/Tracker"

const Layout = ({ children, themeColours }) => {
  return (
    <>
      <Header themeColours={themeColours} />
      {children}
      <Tracker />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  themeColours: PropTypes.array,
}

export default Layout
