import { graphql, Link } from "gatsby"
import React from "react"
import Cards from "../components/Cards"
import Hero from "../components/Hero"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import Footer from "../components/Footer"
import Container from "../components/Container"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SiteMetadata title="Home" description="Portfolio of UK-based digital product designer, Ben Stevens" />

      <Hero />

      <Container classes="md:pt-0 lg:pt-0 xl:pt-0">
        <h2 className="text-2xl md:text-4xl mb-10 tracking-tight">
          Recent projects
        </h2>
              
        {data.portfolio && data.portfolio.nodes.length > 0 ? (
          <>
            <Cards items={data.portfolio.nodes} columns={3} />
          </>
        ) : (
          <div className="container">No projects found.</div>
        )}
        
      </Container>

      <Container>
        <h2 className="text-2xl md:text-4xl mb-10 tracking-tight">
          Let’s work together
        </h2>
        <p className="max-w-prose mb-5 text-lg sm:text-lg">With 20 years&rsquo; experience working in digital design, I can help bring your new website / app / software ideas to life, or revitalise your existing products to meet and exceed your customers&rsquo; expectations.</p>
        <p className="max-w-prose mb-10 text-lg sm:text-lg">Not sure where to start? Get in touch now and we’ll get a conversation started.</p>
        <div className="flex flex-col text-center items-center sm:text-left sm:flex-row space-y-2 sm:space-x-2 sm:space-y-0 text-lg">
          <Link to="/contact/" className="flex-initial button-link button-link-solid">Get in touch</Link>
          <Link to="/about/" className="flex-initial button-link button-link-borderless">Learn more about me</Link>
        </div>
      </Container>

      <Footer />
      
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    portfolio: allContentfulPortfolio(limit: 3 sort: {fields: order, order: ASC} filter: {create: {eq: true}}) {
      nodes {
        ...PortfolioCard
      }
    }
  }
`
