import { Link } from "gatsby"
import React from "react"
import Footer from "../../components/Footer"
import Layout from "../../layouts/Layout"

const FormSuccessPage = () => (
  <Layout>

    <div className="py-12 lg:pb-16">
      <div className="container ">
        <h1 className="text-5xl sm:text-4xl md:text-5xl tracking-tight">
          Your message was sent <span role="img" aria-label="hands raised in celebration">
            🙌
          </span>
        </h1>

        <p className="my-12 font-normal text-lg max-w-2xl">
          <Link to="/form-test/">Return to the form test page</Link>
        </p>
      </div>
    </div>

    <Footer />

  </Layout>
)

export default FormSuccessPage