import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import { motion, useAnimation } from "framer-motion";
import useThemeToggler from '../hooks/useThemeToggler'


const DarkModeToggle = ({ iconWidth }) => {

  const [isInitial, setIsInitial] = useState(true);
  const [theme, toggleTheme] = useThemeToggler();

  const groupControls = useAnimation()

  const groupVariants = {
    hoverdark: { translateY: 0, transition: { duration: 0.2, ease: 'easeOut' } },
    hoverlight: { translateY: 14, transition: { duration: 0.2, ease: 'easeOut' } },
    dark: { translateY: 6, transition: { duration: 0.3 } },
    light: { translateY: 6, transition: { duration: 0.3 } },
    sunrise: { translateY: -24, transition: { duration: 0.2, ease: 'easeIn' } },
    sunset: { translateY: 24, transition: { duration: 0.2, ease: 'easeIn' } }
  };

  const pathCenterVariants = {
    hoverdark: { d: "M12.5 17a5 5 0 1 0 -1 0" },
    hoverlight: { d: "M17 12a5 5 0 1 0 -10 0" },
    dark: { d: "M17 12a5 5 0 1 0 -10 0" },
    light: { d: "M17 12a5 5 0 1 0 -10 0" },
  };

  const lineNVariants = {
    hoverdark: { d: "M12 1l0 2", scale: 1 },
    hoverlight: { d: "M12 3l0 0", scale: 0 },
    dark: { d: "M12 -4l0 7", scale: 1 },
    light: { d: "M12 -4l0 7", scale: 1 },
  };

  const lineNWVariants = {
    hoverdark: { d: "M4.22 4.22l1.42 1.42", scale: 1 },
    hoverlight: { d: "M5.64 5.64l0 0", scale: 0 },
    dark: { d: "M4.22 4.22l1.42 1.42", scale: 1 },
    light: { d: "M4.22 4.22l1.42 1.42", scale: 1 },
  };

  const lineNEVariants = {
    hoverdark: { d: "M18.36 5.64l1.42 -1.42", scale: 1 },
    hoverlight: { d: "M18.36 5.64l0 0", scale: 0 },
    dark: { d: "M18.36 5.64l1.42 -1.42", scale: 1 },
    light: { d: "M18.36 5.64l1.42 -1.42", scale: 1 },
  };

  const lineSWVariants = {
    hoverdark: { d: "M5.64 18.36l-1.42 1.42" },
    hoverlight: { d: "M12 16l-11 0" },
    dark: { d: "M12 16l-11 0" },
    light: { d: "M12 16l-11 0" },
  };

  const lineSEVariants = {
    hoverdark: { d: "M18.36 18.36l1.42 1.42" },
    hoverlight: { d: "M12 16l11 0" },
    dark: { d: "M12 16l11 0" },
    light: { d: "M12 16l11 0" },
  };

  const lineSVariants = {
    hoverdark: { d: "M12 21l0 2" },
    hoverlight: { d: "M12 16l0 2" },
    dark: { d: "M12 16l0 0" },
    light: { d: "M12 16l0 0" },
  };

  const arrowVariants = {
    hoverdark: { points: "12 1 12 1 12 1", scale: 1 },
    hoverlight: { points: "12 3 12 3 12 3", scale: 0 },
    dark: { points: "8 0 12 -4 16 0", scale: 1 },
    light: { points: "16 -1 12 3 8 -1", scale: 1 },
  };

  const doHoverStart = () => {

    const hoverClass = `hover${theme}`
    groupControls.start(hoverClass)
  
  }

  const doHoverEnd = () => {

    groupControls.start(theme)

  }



  useEffect(() => {

    if(!isInitial) {

      // Change theme to light
      // Sun rises above frame
      // Sunset icon falls from above

      const doSunrise = async () => {
        await groupControls.start('hoverdark')
        await groupControls.start('sunrise')
        return await groupControls.start('light')
      }

      // Change theme to dark
      // Sun disappears below frame
      // Sunrise icon appears from below

      const doSunset = async () => {
        await groupControls.start('hoverlight')
        await groupControls.start('sunset')
        return await groupControls.start('dark')
      }

      if (theme === 'dark') {
        doSunset()
      } else {
        doSunrise()
      }

    } else {
      setIsInitial(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme])

  // Don't render anything at compile time. Deferring rendering until we
  // know which theme to use on the client avoids incorrect initial
  // state being displayed.
  if (theme == null) {
    return null
  }
  return (
    <div className="inline-block">
      <label className="flex cursor-pointer p-2 select-none"><span className="sr-only">Toggle dark mode</span>
        <input
          type="checkbox"
          onChange={(e) => { 
            toggleTheme(e.target.checked ? 'dark' : 'light')
            try {
              window.pa.track({name: 'Dark mode toggle', value: theme})
            } catch (err) {
              //fail silently
            }
           }}
          checked={theme === 'dark'}
          className="hidden appearance-none"
          title={`Toggle dark mode`}
        />
        <motion.svg
          initial={theme}
          animate={theme}
          //whileHover={!hasChanged && (theme === 'dark' ? "hoverdark" : "hoverlight")}
          onHoverStart={e => { doHoverStart() }}
          onHoverEnd={e => { doHoverEnd() }}
          width={iconWidth}
          height={iconWidth}
          viewBox="0 0 24 24"
          stroke="currentColor" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
          title="Toggle dark mode"
        >
          <motion.g
            initial={theme}
            animate={groupControls}
            variants={groupVariants}>

            <motion.path d="M17 12a5 5 0 1 0-10 0" variants={pathCenterVariants} />

            <motion.path d="M12 -4l0 7" variants={lineNVariants} />

            <motion.path id="sun_line_nw" d="M4.22 4.22l1.42 1.42" variants={lineNWVariants} />
            <motion.path id="sun_line_ne" d="M18.36 5.64l1.42 -1.42" variants={lineNEVariants} />
            <motion.line id="sun_line_w" x1="1" y1="12" x2="3" y2="12" />
            <motion.line id="sun_line_e" x1="21" y1="12" x2="23" y2="12" />

            <motion.path id="sun_line_sw" d="M5.64 18.36l-1.42 1.42" variants={lineSWVariants} />
            <motion.path id="sun_line_se" d="M18.36 18.36l1.42 1.42" variants={lineSEVariants} />
            <motion.path id="sun_line_s" d="M12 21l0 2" variants={lineSVariants} />

            <motion.polyline
              id="sun_polyline_arrow"
              points="8 0 12 -4 16 0"
              variants={arrowVariants} />

          </motion.g>

        </motion.svg>
      </label>
    </div>
  )
}

DarkModeToggle.propTypes = {
  iconWidth: PropTypes.node,
}

DarkModeToggle.defaultProps = {
  iconWidth: 24,
}

export default DarkModeToggle