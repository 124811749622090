import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"

const Title = ({title, noMargin}) => (
  <h1 className={classNames("text-4xl sm:text-4xl md:text-5xl tracking-tight max-w-2xl", {
    "mb-10" : !noMargin
  })} dangerouslySetInnerHTML={{
    __html: title,
  }}>
  </h1>
)

Title.propTypes = {
  title: PropTypes.string.isRequired,
  noMargin: PropTypes.bool,
}


export default Title
