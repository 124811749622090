import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import { FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa"
import Container from "./Container"

const Footer = (props) => {
  const {
    site: {
      meta: { links },
      data: { menu },
    },
  } = useStaticQuery(graphql`
    query FooterQuery {
      site {
        meta: siteMetadata {
          links {
            linkedin
            instagram
            twitter
          }
        }
        data: siteMetadata {
          menu {
            name
            to
          }
        }
      }
    }
  `)

  return (
    <footer className="footer">
      <Container classes="md:flex md:items-center md:justify-between">
        <div className="mt-0 text-center md:text-left text-sm md:text-base dark:text-gray space-y-4 mb-8 md:mb-4">
          <nav>
            <ul className="flex flex-col md:flex-row md:space-x-1 space-y-1 md:space-y-0 text-lg mb-8 md:-ml-3">
              {menu.map((link, key) =>
                <li><Link
                  key={`footer_link${key}`}
                  className="inline-block px-3 py-2"
                  to={link.to}
                >
                  {link.name}
                </Link></li>
              )}
              <li><Link
                className="inline-block px-3 py-2"
                to="/sustainability/"
              >
                Sustainability{" "}<span role="img" aria-label="globe">🌍</span>
              </Link></li>
            </ul>
          </nav>
          <p>
            &copy; 2022 Ben Stevens &ndash; <a href="https://supernova.design" target="_blank" rel="noreferrer noopener">Supernova Design</a></p>
        </div>
        <ul className="flex justify-center">
          <FooterLink href={links.twitter} icon={FaTwitter} label="Twitter" />
          <FooterLink href={links.linkedin} icon={FaLinkedin} label="Linkedin" />
          <FooterLink
            href={links.instagram}
            icon={FaInstagram}
            label="Instagram"
          />

        </ul>
      </Container>
    </footer>
  )
}

const FooterLink = ({ href, label, icon: Icon }) => {
  return (
    <li className="inline-block px-3">
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        className="social-link">
        <span className="sr-only">{label}</span>
        <Icon className="w-7 h-7 sm:w-6 sm:h-6 fill-current" />
      </a>
    </li>
  )
}

export default Footer
