import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"


const Container = ({ children, classes, fullWidth }) => {

  return (
    <div className={classNames("container py-6 md:py-12 lg:py-16 xl:py-20", {
      [classes] : classes,
      'full-width' : fullWidth === true
    })}>
      {children}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
  fullWidth: PropTypes.bool
}

Container.defaultProps = {
  classes: '',
  fullWidth: false
}

export default Container