import PropTypes from "prop-types"
import React from "react"
import FootprintTableRow from "./FootprintTableRow"

const FootprintTable = ({ items, caption }) => {
  return (
    <table className="table-auto border-collapse">
      { caption && (
        <caption>{caption}</caption>
      )}
      <thead>
        <tr>
          <th>Page</th>
          <th>CO<sub>2</sub> / page view (g)</th>
          <th>Last updated</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <FootprintTableRow {...item} key={item.id} />
        ))}
      </tbody>
    </table>
  )
}

FootprintTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  caption: PropTypes.string,
}

export default FootprintTable
