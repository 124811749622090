import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import Footer from "../components/Footer";
import Container from "../components/Container";

const AboutPage = ({ data }) => (
  <Layout>
    <SiteMetadata title="About" description="" />

    <article>
      <Container>
        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2 xl:w-4/7 pb-8 md:pb-0 text-lg lg:text-xl leading-relaxed lg:leading-relaxed">
            <h1 className="text-3xl sm:text-4xl mb-10 tracking-tight sm:leading-tight">
              I'm Ben, a freelance digital product designer living and working in the UK
            </h1>

            <p className="lead">
              I specialise in user experience design for digital projects such as websites, mobile apps, software applications, games, and interactive displays for museums.
            </p>

            <p>
              I've worked with the European Environment Agency, Institute of Development Studies, UCL, V&amp;A Museum, London School of Economics, and Paperchase... as well as many small businesses and digital agencies. You can see some of my recent work <Link to="/work/" className="link">here.</Link>
            </p>

            <p>
              I'm keen to support businesses that have ambitious environmental roadmaps for their digital products and services. You can read more about some of the steps I'm taking on my <Link to="/sustainability" className="link">sustainability</Link> page.
            </p>

            <p>
              If you're looking to hire a UX/UI product designer for an upcoming project, then please <Link to="/contact/" className="link">get in touch.</Link>
            </p>

          </div>
          <div className="w-full md:w-1/2 xl:w-3/7 md:pl-16 xl:pl-20">
            <GatsbyImage
              image={data.author.childImageSharp.gatsbyImageData}
              alt="Me and the family"
              className="rounded-md shadow-md"
              backgroundColor="rgba(128,128,128,0.15)" />
          </div>
        </div>
      </Container>
    </article>

    <Footer />

  </Layout>
)

export default AboutPage

export const query = graphql`{
  author: file(relativePath: {eq: "author.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, height: 480, quality: 85, placeholder: NONE, layout: CONSTRAINED)
    }
  }
}
`
