import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import { Link } from "gatsby"
import Footer from "../components/Footer"
import Container from "../components/Container"

const ErrorPage = () => (
  <Layout>
    <SiteMetadata title="404" description="This page is missing" />

    <Container>
      <h1 className="text-5xl sm:text-4xl md:text-5xl tracking-tight">
        This page is missing <span role="img" aria-label="face screaming in fear">
          😱
        </span>
      </h1>

      <p className="my-12 font-normal text-lg max-w-2xl">
        Please <Link to="/contact/" title={`Contact`} className="font-bold border-b border-gray hover:border-blue-600 hover:text-blue-600">get in touch</Link> if you need something that used to be here
      </p>
    </Container>

    <Footer />

  </Layout>
)

export default ErrorPage
