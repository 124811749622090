import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const ArchiveCard = props => {
  const { name, slug, thumbnail, publishedDate } = props
  const image = getImage(thumbnail)

  return (
    <div className="flex justify-start flex-col h-full">
      <Link to={`/archive/${slug}/`}>
        <div className="w-full">
          {thumbnail && (
            <GatsbyImage
              image={image}
              alt={name}
              className="shadow-md rounded-lg overflow-hidden transform hover:scale-102 transition duration-200 ease-in-out mb-4"
              backgroundColor="rgba(128,128,128,0.15)" />
          )}
        </div>
        <h1 className="text-base">{name}</h1>
      </Link>
      <p className="text-sm mt-2 mb-4">{ new Date(publishedDate).toLocaleDateString()}</p>
    </div>
  );
}

ArchiveCard.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  publishedDate: PropTypes.string.isRequired,
  thumbnail: PropTypes.object,
}

export default ArchiveCard

export const query = graphql`fragment ArchiveCard on ContentfulArchive {
  id
  name
  slug
  publishedDate
  thumbnail {
    gatsbyImageData(width: 356, height: 275, quality: 85, placeholder: NONE, cropFocus: CENTER, layout: CONSTRAINED)
  }
}
`
