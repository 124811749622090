import React from "react"
import ContactForm from "../../components/ContactForm"
import Footer from "../../components/Footer"
import Layout from "../../layouts/Layout"

const FormTestPage = ({location}) => (
  <Layout>

    <div className="py-12 lg:pb-16">
      <div className="container ">
        <h1 className="text-5xl sm:text-4xl md:text-5xl tracking-tight">
          Form test
        </h1>

        <ContactForm location={location} />

      </div>
    </div>

    <Footer />

  </Layout>
)

export default FormTestPage