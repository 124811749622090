import { useEffect } from "react"
import useTrackingScript from '../hooks/useTrackingScript'

const Tracker = () => {

  const status = useTrackingScript('https://app.microanalytics.io/js/script.js', 'ZwSg9rf6GA')

  useEffect(()=> {

    if(status === 'ready') {
      //tracking is loaded
    }

  },[status])

  return(null)

}

export default Tracker
