import React, { useEffect, useState } from "react"
import { motion, useAnimation, useMotionValue, useTransform } from "framer-motion";

const LogoAnimationTestPage = () => {

  const controls = useAnimation();

  const [loopCount, setLoopCount] = useState(0);

  const h = useMotionValue( 30 );
  const h2 = useTransform( h, value => 72 - value);
  const h3 = useMotionValue( 30 );

  useEffect(() => {

    let interval = null;

    interval = setInterval(() => {
      controls.start(i => {
        console.log(i);
        switch(i) {
          case 'rect1':
            return { height: 12 + (Math.random() * 48) }
          case 'rect2':
            return { height: 0 + (Math.random() * 48) }
          default:
            return false;
        }
      })
      setLoopCount(c => c + 1)
    }, 1250);

    return () => clearInterval(interval);

  }, [loopCount, controls]);

  //useEffect(() => h.onChange(latest => { console.log(latest) }), [])

  return (
    <div className="my-16 space-y-16">

      <p>{loopCount} {h.get()}</p>

      <div className="flex justify-center space-x-16">
        <div className="text-center inline-block">
        <motion.svg 
        width="288" 
        height="288" 
        viewBox="0 0 72 72" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
              <path d="M39 0C41.7578 3.2886e-08 44.4885 0.543181 47.0364 1.59853C49.5842 2.65388 51.8992 4.20073 53.8492 6.15076C55.7993 8.10079 57.3461 10.4158 58.4015 12.9637C59.4568 15.5115 60 18.2422 60 21C60 23.7578 59.4568 26.4885 58.4015 29.0364C57.3461 31.5842 55.7993 33.8992 53.8492 35.8493C51.8992 37.7993 49.5842 39.3461 47.0363 40.4015C44.4885 41.4568 41.7577 42 39 42L39 21L39 0Z" fill="#969696" />
              <path d="M39 30C41.7578 30 44.4885 30.5432 47.0364 31.5985C49.5842 32.6539 51.8992 34.2007 53.8492 36.1508C55.7993 38.1008 57.3461 40.4158 58.4015 42.9637C59.4568 45.5115 60 48.2422 60 51C60 53.7578 59.4568 56.4885 58.4015 59.0364C57.3461 61.5842 55.7993 63.8992 53.8492 65.8493C51.8992 67.7993 49.5842 69.3461 47.0363 70.4015C44.4885 71.4568 41.7577 72 39 72L39 51L39 30Z" fill="#376CF4" />
              <motion.rect 
                x="0" 
                y={h} 
                width="39" 
                height={h2} 
                fill="#50E3C2" />
              <motion.rect 
                x="0" 
                y="0" 
                width="39"
                height={h}
                fill="#376CF4" 
                animate={controls}
                custom={'rect1'}
                 />
              <motion.rect 
                x="12" 
                y="60" 
                width="27" 
                height="12" 
                fill="#F75E70" />
              <motion.rect 
                x="12" 
                y="30" 
                width="27" 
                height="30" 
                fill="#F75E70" />
              <path d="M39 42C41.3869 42 43.6761 42.9482 45.364 44.636C47.0518 46.3239 48 48.613 48 51C48 53.3869 47.0518 55.6761 45.364 57.364C43.6761 59.0518 41.387 60 39 60L39 51L39 42Z" fill="#DBDBDB" />
              <rect x="12" y="42" width="27" height="18" fill="#252525" />
              <path d="M39 12C41.3869 12 43.6761 12.9482 45.364 14.636C47.0518 16.3239 48 18.613 48 21C48 23.3869 47.0518 25.6761 45.364 27.364C43.6761 29.0518 41.387 30 39 30L39 21L39 12Z" fill="#252525" />
              <motion.rect 
                x="12" 
                y="12" 
                width="27" 
                height={h3} 
                fill="#DBDBDB" 
                animate={controls}
                custom={'rect2'} />
            </motion.svg>
        </div>
      </div>

    </div>
  )
}

export default LogoAnimationTestPage