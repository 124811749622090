import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import Card from "./Card"
import PostCard from "./PostCard"
import WideCard from "./WideCard"

const Cards = ({ items, columns = 1, wide = false, isBlog = false }) => {
  return (
    <div className="flex flex-wrap justify-start -my-3 -mx-3 lg:-mx-8">
      {items.map((item, index) => (
        <div
          className={classNames("p-3", {
            "md:w-1/3": columns === 3,
            "md:w-1/4": columns === 4,
            "w-auto sm:w-1/2": wide === false,
            "w-full md:py-6": wide === true,
          })}
          key={item.id}
        >
          {
            isBlog === true ? (
              <PostCard {...item} />
            ) : (
              wide === true ? (
                <WideCard {...item} />
              ) : (
                <Card {...item} />
              )
            )
          }
        </div>
      ))}
    </div>
  )
}

Cards.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Cards
