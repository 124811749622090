import React from "react"
import DarkModeToggle from "../../components/DarkModeToggle";

const SvgTestPage = () => {

  return (
    <div className="my-16 space-y-16">

      <div className="flex justify-center space-x-16">

        <DarkModeToggle iconWidth="240"></DarkModeToggle>

      </div>

      <div className="flex justify-center space-x-16">
        <div className="text-center inline-block">
          <svg className="" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path id="sun_path_center" d="M17 18a5 5 0 1 0-10 0"></path>
            <line id="sun_line_n" x1="12" y1="2" x2="12" y2="9"></line>
            <line id="sun_line_nw" x1="4.22" y1="10.22" x2="5.64" y2="11.64"></line>
            <line id="sun_line_w" x1="1" y1="18" x2="3" y2="18"></line>
            <line id="sun_line_e" x1="21" y1="18" x2="23" y2="18"></line>
            <line id="sun_line_ne" x1="18.36" y1="11.64" x2="19.78" y2="10.22"></line>
            <line id="sun_line_sw" x1="4.22" y1="22" x2="4.22" y2="22"></line>
            <line id="sun_line_se" x1="19.78" y1="22" x2="19.78" y2="22"></line>
            <line id="sun_line_s" x1="12" y1="22" x2="12" y2="22"></line>
            <line id="sun_line_horizon" x1="23" y1="22" x2="1" y2="22"></line>
            <polyline id="sun_polyline_arrow" points="8 6 12 2 16 6"></polyline>
          </svg>
        </div>

        <div className="inline-block">
          <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path id="sun_path_center" d="M16 18a5 5 0 1 0 -8 0"></path>
            <line id="sun_line_n" x1="12" y1="4" x2="12" y2="6"></line>
            <line id="sun_line_nw" x1="4.22" y1="7.22" x2="5.64" y2="8.64"></line>
            <line id="sun_line_w" x1="1" y1="15" x2="3" y2="15"></line>
            <line id="sun_line_e" x1="21" y1="15" x2="23" y2="15"></line>
            <line id="sun_line_ne" x1="18.36" y1="8.64" x2="19.78" y2="7.22"></line>
            <line id="sun_line_sw" x1="4.22" y1="22" x2="4.22" y2="22"></line>
            <line id="sun_line_se" x1="19.78" y1="22" x2="19.78" y2="22"></line>
            <line id="sun_line_s" x1="12" y1="22" x2="12" y2="22"></line>
            <line id="sun_line_horizon" x1="19.78" y1="22" x2="4.22" y2="22"></line>
            <polyline id="sun_polyline_arrow" points="12 4 12 4 12 4"></polyline>
          </svg>
        </div>

        <div className="inline-block">
          <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <g>
              <path id="sun_path_center" d="M12.5 17a5 5 0 1 0 -1 0"></path>
              <line id="sun_line_n" x1="12" y1="1" x2="12" y2="3"></line>
              <line id="sun_line_nw" x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
              <line id="sun_line_w" x1="1" y1="12" x2="3" y2="12"></line>
              <line id="sun_line_e" x1="21" y1="12" x2="23" y2="12"></line>
              <line id="sun_line_ne" x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
              <line id="sun_line_sw" x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
              <line id="sun_line_se" x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
              <line id="sun_line_s" x1="12" y1="23" x2="12" y2="21"></line>
              <line id="sun_line_horizon" x1="12" y1="23" x2="12" y2="23"></line>
            </g>
            <polyline id="sun_polyline_arrow" points="12 1 12 1 12 1"></polyline>
          </svg>
        </div>

        <div className="text-center inline-block">
          <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path id="sun_path_center" d="M17 18a5 5 0 0 0 -10 0"></path>
            <line id="sun_line_n" x1="12" y1="2" x2="12" y2="9"></line>
            <line id="sun_line_nw" x1="4.22" y1="10.22" x2="5.64" y2="11.64"></line>
            <line id="sun_line_w" x1="1" y1="18" x2="3" y2="18"></line>
            <line id="sun_line_e" x1="21" y1="18" x2="23" y2="18"></line>
            <line id="sun_line_ne" x1="18.36" y1="11.64" x2="19.78" y2="10.22"></line>
            <line id="sun_line_sw" x1="4.22" y1="22" x2="4.22" y2="22"></line>
            <line id="sun_line_se" x1="19.78" y1="22" x2="19.78" y2="22"></line>
            <line id="sun_line_s" x1="12" y1="22" x2="12" y2="22"></line>
            <line id="sun_line_horizon" x1="23" y1="22" x2="1" y2="22"></line>
            <polyline id="sun_polyline_arrow" points="16 5 12 9 8 5"></polyline>
          </svg>
        </div>

        <div className="inline-block">
          <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path id="sun_path_center" d="M15 18a5 5 0 0 0 -6 0"></path>
            <line id="sun_line_n" x1="12" y1="13" x2="12" y2="13"></line>
            <line id="sun_line_nw" x1="5.64" y1="15.64" x2="5.64" y2="15.64"></line>
            <line id="sun_line_w" x1="3" y1="22" x2="3" y2="22"></line>
            <line id="sun_line_e" x1="21" y1="22" x2="21" y2="22"></line>
            <line id="sun_line_ne" x1="18.36" y1="15.64" x2="18.36" y2="15.64"></line>
            <line id="sun_line_sw" x1="5.64" y1="22" x2="5.64" y2="22"></line>
            <line id="sun_line_se" x1="18.36" y1="22" x2="18.36" y2="22"></line>
            <line id="sun_line_s" x1="12" y1="22" x2="12" y2="22"></line>
            <line id="sun_line_horizon" x1="23" y1="22" x2="1" y2="22"></line>
            <polyline id="sun_polyline_arrow" points="12 13 12 13 12 13"></polyline>
          </svg>
        </div>


        <div className="inline-block">
          <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path id="sun_path_center" d="M12 22a5 5 0 0 0 -0.01 0"></path>
            <line id="sun_line_n" x1="12" y1="22" x2="12" y2="22"></line>
            <line id="sun_line_nw" x1="12" y1="22" x2="12" y2="22"></line>
            <line id="sun_line_w" x1="12" y1="22" x2="12" y2="22"></line>
            <line id="sun_line_e" x1="12" y1="22" x2="12" y2="22"></line>
            <line id="sun_line_ne" x1="12" y1="22" x2="12" y2="22"></line>
            <line id="sun_line_sw" x1="12" y1="22" x2="12" y2="22"></line>
            <line id="sun_line_se" x1="12" y1="22" x2="12" y2="22"></line>
            <line id="sun_line_s" x1="12" y1="22" x2="12" y2="22"></line>
            <line id="sun_line_horizon" x1="23" y1="22" x2="1" y2="22"></line>
            <polyline id="sun_polyline_arrow" points="12 22 12 22 12 22"></polyline>
          </svg>
        </div>

      </div>

    </div>
  )
}

export default SvgTestPage