import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react"
import SiteMetadata from "../components/SiteMetadata"
import Cards from "../components/Cards"
import Layout from "../layouts/Layout"
import CTABlock from "../components/CTABlock";
import RichTextBlock from "../components/RichTextBlock";
import Breadcrumbs from "../components/Breadcrumbs";
import Footer from "../components/Footer";
import Container from "../components/Container";
import Title from "../components/Title";

const PortfolioTemplate = ({data}) => {
  const {
    description,
    gallery,
    name,
    related,
    summary,
    role,
    launchDate,
    thumbnail,
    url,
    credits,
    themeColours
  } = data.item

  return (
    <Layout themeColours={themeColours}>
      <SiteMetadata
        title={name}
        description={summary}
        image={thumbnail && thumbnail.file.url}
      />
      <article>
        <Container>
          <div className="flex justify-center">
            <div className="w-full md:w-4/5 lg:w-2/3">
              <Breadcrumbs name="Work" link="/work/" />
              <Title title={name} />
              {(role || launchDate || url) && (
                <dl className="text-lg lg:text-xl leading-relaxed lg:leading-loose">
                  {role && (
                    <>
                      <dt className="font-bold">
                        Role
                      </dt>
                      <dd>
                        {role}
                      </dd>
                    </>
                  )}
                  {launchDate && (
                    <>
                      <dt className="font-bold">
                        Launched
                      </dt>
                      <dd>
                        {launchDate}
                      </dd>
                    </>
                  )}
                  {url && (
                    <>
                      <dt className="font-bold">
                        URL
                      </dt>
                      <dd>
                        <a href={url} rel="noopener noreferrer" target="_blank" className="ext-link">{url}</a>
                      </dd>
                    </>
                  )}
                </dl>
              )}
            </div>
          </div>
        </Container>
        {gallery && (
          <Container classes="md:pt-0 lg:pt-0 xl:pt-0">
            <div className="flex flex-wrap ">
              <div className="w-full flex-grow -mx-4 md:mx-0">
                {gallery.length >= 1 && (
                  <GatsbyImage
                    image={gallery[0].gatsbyImageData}
                    alt={name}
                    className=""
                    backgroundColor="rgba(128,128,128,0.15)" />
                )}
              </div>
            </div>
          </Container>
        )}
        {description && (
          <Container classes="md:pt-0 lg:pt-0 xl:pt-0">
            <RichTextBlock description={description} />
          </Container>
        )}
        {credits && (
          <Container>
            <div className="flex justify-center">
              <div className="w-full lg:w-2/3">
                <h4 className="text-2xl sm:text-3xl leading-tight font-extrabold tracking-tight mb-8">
                  Credits
                </h4>
                <div
                  className="my-4 text-lg leading-relaxed lg:leading-loose"
                  dangerouslySetInnerHTML={{
                    __html: credits.childMarkdownRemark.html,
                  }}
                />
              </div>
            </div>
          </Container>
        )}
      </article>
      
      {related && (
        <aside className="bg-white dark:bg-gray-dark py-12 lg:py-16">
          <Container>
            <h5 className="text-2xl sm:text-3xl leading-tight font-extrabold tracking-tight mb-10">
              You might also like
            </h5>
            <Cards items={related} columns={3} />
          </Container>
        </aside>
      )}

      <CTABlock />
      <Footer />
    </Layout>
  );
}

export default PortfolioTemplate

export const query = graphql`query PortfolioItemQUery($slug: String!) {
  item: contentfulPortfolio(slug: {eq: $slug}) {
    description {
      raw
      references {
        ... on ContentfulAsset {
          contentful_id
          __typename
          title
          description
          gatsbyImageData(width: 1024, quality: 85, placeholder: DOMINANT_COLOR)
        }
      }
    }
    gallery {
      id
      gatsbyImageData(width: 1200, height: 900, quality: 85, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
      title
    }
    name
    related {
      ...PortfolioCard
    }
    summary
    role
    launchDate
    url
    credits {
      childMarkdownRemark {
        html
      }
    }
    themeColours
    thumbnail {
      file {
        url
      }
    }
  }
}
`
