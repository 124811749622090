import React from "react"
import SiteMetadata from "../components/SiteMetadata"
import Container from "../components/Container"
import Tracker from "../components/Tracker"
import "../styles/style.css"


const CvProductDesigner = () => {

  return (
    <>
      <SiteMetadata title="CV" description="" />
      <Container>
        <header className="flex flex-col-reverse md:flex-row justify-between">
          <h1 className="cv-header text-3xl md:text-5xl print:text-5xl tracking-tight">
            Ben Stevens <br /><span className="md:text-4xl print:text-4xl">Lead Product Designer</span>
          </h1>
          <div className="flex-grow-0 text-right text-lg print:hidden mb-4 md:mb-0">
            <a href="/BenStevens-CV-Product-Designer.pdf" className="inline-block button-link text-sm md:text-lg">
              Download PDF
            </a>
          </div>
        </header>

        <div className="cv-section flex flex-col md:flex-row print:flex-row md:space-x-16 print:space-x-16 leading-loose">
          <p className="">
            Email <a href="mailto:ben@benstevens.co.uk">ben@benstevens.co.uk</a> <br />
            Website <a href="https://www.benstevens.co.uk">www.benstevens.co.uk</a>
          </p>
          <p className="">
            LinkedIn <a href="https://www.linkedin.com/in/benstevens">www.linkedin.com/in/benstevens</a> <br />
            Twitter <a href="https://twitter.com/benstevens">twitter.com/benstevens</a>
          </p>
        </div>



        <div className="cv-section">
          <ul className="cv-list text-xl max-w-prose print:max-w-none">
            <li>Contract designer with 10+ years in Senior &amp; Lead roles</li>
            <li>Versatile skill set covering UX, UI, and front-end web development</li>
            <li>Working knowledge of the entire product design workflow, from brief to release</li>
            <li>Confident in guiding clients &amp; stakeholders on important design and technical matters</li>
            <li>Experience in supervising and mentoring junior creatives</li>
            <li>Enjoy learning new skills, meeting new people, and finding better ways of collaborating</li>
          </ul>
        </div>

        <div className="cv-section">
          <h2 className="text-2xl uppercase font-normal tracking-wide mb-12 max-w-prose print:max-w-none">Professional experience</h2>

          <div className="max-w-prose print:max-w-none mt-12 mb-16">
            <h3 className="text-3xl tracking-tight">UX Designer / UI Designer / Developer</h3>
            <p className="text-xl font-bold">Self-employed</p>
            <p className="">March 2008&mdash;date</p>
            <p className="my-6">Since 2008 I have helped small businesses, digital agencies, charities, and non-profit organisations launch several successful digital projects as a lead UX/UI designer. I have collaborated with multi-disciplinary, agile teams across a variety of industries, from education and retail to international development.</p>
            <p className="my-6">Although I specialise in user experience, I am a strong generalist and enjoy contributing to all parts of the design process - from research &amp; discovery to defining user journeys, wireframes, visual designs, and engineering.</p>
            <p className="my-6">Project highlights:</p>
            <ul className="cv-list my-6">
              <li><a href="https://www.benstevens.co.uk/paperchase-plus">Paperchase Plus</a> – a brand new app offering premium personalised products from Paperchase</li>
              <li><a href="https://www.benstevens.co.uk/lse-student-hub">LSE Student Hub</a> – the student community app for the London School of Economics</li>
              <li><a href="https://www.benstevens.co.uk/partnerships-for-forests/">Partnerships for Forests</a> – a website for the Foreign Commonwealth and Development Office supporting partnerships between the private sector and communities that depend on forests for their livelihoods</li>
              <li><a href="https://www.benstevens.co.uk/abc-joined-up/">ABC Joined Up</a> – An interactive iPad app for Letter Layers that shows 4 to 8 year-olds how to do joined-up handwriting</li>
              <li>Created interactive touchscreen games for an information centre in Kuwait, a visitors centre in St Lucia, and a museum in Turkey</li>
            </ul>
            <p className="my-6">Other clients include the Alzheimer's Society, Institute of Development Studies, Overseas Development Institute (ODI), University College London (UCL), rehabstudio, and ustwo.</p>
          </div>

          <div className="max-w-prose print:max-w-none mt-12 mb-16">
            <h3 className="text-3xl tracking-tight">Lead Product Designer - UX (Contract)</h3>
            <p className="text-xl font-bold">Paperchase</p>
            <p className="">December 2019&mdash;January 2021</p>
            <ul className="cv-list my-6">
              <li>Lead UX Designer for the Paperchase Plus app, offering premium personalised products from Paperchase.</li>
              <li>Worked as part of a remote team of designers, developers, and print specialists to deliver the product during the 1st lockdown of 2020</li>
              <li>I led the research phase of the project and helped to define the various user journeys through onboarding, personalisation, and checkout</li>
              <li>Helped to establish a design system and a user problem research workflow to streamline future design efforts</li>
            </ul>
          </div>

          <div className="max-w-prose print:max-w-none mt-12 mb-16">
            <h3 className="text-3xl tracking-tight">Lead Product Designer - UX (Contract)</h3>
            <p className="text-xl font-bold">London School of Economics</p>
            <p className="">January 2018&mdash;March 2018</p>
            <ul className="cv-list my-6">
              <li>Worked with LSE to create the Student Hub, an app where students can organise their work, connect with groups in the school community, and discover the campus environment around them</li>
              <li>As Lead UX Designer, I helped to distil the research gathered from student workshops into user journey maps for stakeholder presentations</li>
              <li>Created wireframes for both the native iOS/Android app(s) and web-based portal</li>
              <li>Collaborated with a team of UI designers &amp; engineers to deliver the final product</li>
            </ul>
          </div>

          <div className="max-w-prose print:max-w-none mt-12 mb-16">
            <h3 className="text-3xl tracking-tight">Interactive Developer (Contract, Various projects)</h3>
            <p className="text-xl font-bold">Newangle</p>
            <p className="">January 2015&mdash;July 2018</p>
            <p className="my-6">I collaborated with Newangle as an Interactive Developer (Unity3D) on several exhibitions:</p>
            <ul className="cv-list my-6">
              <li>South London Botanical Institute (Jul 2018)</li>
              <li>Winnie-the-Pooh Exhibition, V&amp;A Museum London (Dec 2017)</li>
              <li>Arabic Islamic Science Museum, Kuwait (Nov 2017)</li>
              <li>Fethard Horse Country Experience, Ireland (Mar 2017)</li>
              <li>AGPLM, Turkey (Jan 2016)</li>
            </ul>
          </div>

          <div className="max-w-prose print:max-w-none mt-12 mb-16">
            <h3 className="text-3xl tracking-tight">Senior Digital Designer</h3>
            <p className="text-xl font-bold">Liquid Digital</p>
            <p className="">April 2007&mdash;March 2008</p>
            <ul className="cv-list my-6">
              <li>Lead designer/developer on all Flash-based projects (websites, applications, interactive kiosks)</li>
              <li>Creation of motion graphics for digital signage networks</li>
              <li>Supervised junior designers &amp; interns</li>
              <li>Acted as the primary liaison between creative and technical teams</li>
            </ul>
          </div>

          <div className="max-w-prose print:max-w-none mt-12 mb-16">
            <h3 className="text-3xl tracking-tight">Senior Creative</h3>
            <p className="text-xl font-bold">N1 Creative</p>
            <p className="">October 2004&mdash;October 2006</p>
            <ul className="cv-list my-6">
              <li>Led the digital creative team to win several industry awards at this boutique digital agency</li>
              <li>Responsible for meeting clients and discussing their digital project requirements</li>
              <li>Supervised &amp; directed interns, and briefed freelance creatives &amp; developers</li>
            </ul>
          </div>

          <div className="max-w-prose print:max-w-none mt-12 mb-16">
            <h3 className="text-3xl tracking-tight">Digital Designer</h3>
            <p className="text-xl font-bold">Screenplay Communication</p>
            <p className="">July 2003&mdash;September 2004</p>
            <ul className="cv-list my-6">
              <li>Lead digital &amp; web designer</li>
              <li>I led the design and development of a client-facing web portal for digital asset management</li>
              <li>Created animated graphics for various digital in-store screen networks</li>
            </ul>
          </div>

          <div className="max-w-prose print:max-w-none my-16 mb-0">
            <h3 className="text-3xl tracking-tight">Junior Designer/Animator</h3>
            <p className="text-xl font-bold">MEI Digital</p>
            <p className="">May 2001&mdash;December 2001</p>
            <p className="mt-6">I worked as a designer/animator at MEI Digital on a part-time basis during my degree course, creating interactive and animated assets for WHSmith’s digital signage networks in the UK and the US.</p>
          </div>
        </div>


        <div className="cv-section">
          <h2 className="text-2xl uppercase font-normal tracking-wide mb-12 max-w-prose print:max-w-none">Education</h2>

          <div className="max-w-prose print:max-w-none my-12 mb-0">
            <h3 className="text-3xl tracking-tight">University of Westminster</h3>
            <p className="text-xl">BA(hons) Graphic Information Design &ndash; First class</p>
            <p className="">1999&mdash;2003</p>

          </div>
        </div>

      </Container>
      <Tracker />

    </ >
  )
}

export default CvProductDesigner
