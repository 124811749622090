import { useState, useEffect } from "react";

const useThemeToggler = () => {
  const windowGlobal = typeof window !== 'undefined' && window
  const [theme, setTheme] = useState(windowGlobal.__theme);

  useEffect(() => {
    setTheme(windowGlobal.__theme);

    windowGlobal.__onThemeChange = () => {
      setTheme(windowGlobal.__theme);
    };
  }, [theme,windowGlobal]);

  const toggleTheme = theme => {
    windowGlobal.__setPreferredTheme(theme);
    setTheme(theme);
  };

  return [theme, toggleTheme];
};

export default useThemeToggler;