import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const WideCard = props => {
  const { name, slug, summary, thumbnail, tags, featured } = props
  const image = getImage(thumbnail)

  return (
    <div className="flex flex-wrap h-full">
      <div className="w-full sm:w-1/2 md:w-2/5">
        <Link to={`/${slug}/`} title={name}>
          <GatsbyImage
            image={image}
            alt={name}
            className="shadow-lg rounded-2xl overflow-hidden transform hover:scale-102 transition duration-200 ease-in-out"
            backgroundColor="rgba(128,128,128,0.15)" />
        </Link>
      </div>
      <div className="w-full sm:w-1/2 md:w-3/5 py-8 sm:px-8 sm:py-4 lg:py-8">
        {featured && (
          <p className="text-sm lg:text-base text-gray-aaa_dark dark:text-gray-aaa_light uppercase">Case Study</p>
        )}
        <Link to={`/${slug}/`} title={name}>
          <h1 className="text-2xl lg:text-3xl">{name}</h1>
        </Link>
        <p className="text-base sm:text-large lg:text-xl text-gray-aaa_dark dark:text-gray-aaa_light">{summary}</p>
        {
          tags && (<ul className="w-full lg:w-2/3 my-4 text-black dark:text-black text-sm sm:text-base">
            {tags.map((tag, i) => (
              <li key={i} className="inline-block my-1 mr-2 bg-gray-light dark:bg-gray px-2 rounded">
                {tag}
              </li>
            ))}
          </ul>)
        }
      </div>
    </div>
  );

}

WideCard.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  projectType: PropTypes.string,
  tags: PropTypes.array,
  thumbnail: PropTypes.object,
}

export default WideCard

export const query = graphql`fragment PortfolioCardWide on ContentfulPortfolio {
  id
  name
  slug
  order
  thumbnail {
    gatsbyImageData(width: 496, height: 383, quality: 85, placeholder: NONE, cropFocus: CENTER, layout: CONSTRAINED)
  }
  summary
  projectType
  tags
  featured
}
`
