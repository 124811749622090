import { graphql } from "gatsby";
import React from "react"
import SiteMetadata from "../components/SiteMetadata"
import Layout from "../layouts/Layout"
import RichTextBlock from "../components/RichTextBlock";
import Footer from "../components/Footer";
import Container from "../components/Container";
import WaveEmoji from "../components/WaveEmoji";
import PortfolioBlock from "../components/PortfolioBlock";

const PrivatePortfolioTemplate = ({data}) => {
  const {
    title,
    intro,
    outro,
    portfolios
  } = data.item

  return (
    <Layout>
      <SiteMetadata
        title={title}
        description=""
      />
      <article>
        <Container>
          <div className="flex justify-center">
            <div className="w-full md:w-4/5 lg:w-2/3">
              <h1 className="text-5xl md:text-6xl tracking-tight text-center">
                {title} <WaveEmoji />
              </h1>
            </div>
          </div>
        </Container>

        {intro && (
          <Container classes="md:pt-0 lg:pt-0 xl:pt-0 text-left md:text-center">
            <RichTextBlock description={intro} />
          </Container>
        )}

        {portfolios && portfolios.map((item, index) => (
          <PortfolioBlock {...item} key={item.id} index={index} />
        ))}

        {outro && (
          <Container classes="py-16 text-left md:text-center">
            <RichTextBlock description={outro} />
          </Container>
        )}

      </article>
      
      <Footer />
    </Layout>
  );
}

export default PrivatePortfolioTemplate

export const query = graphql`query PrivatePortfolioQuery($slug: String!) {
  item: contentfulPrivatePortfolio(slug: {eq: $slug}) {
    intro {
      raw
    }
    outro {
      raw
    }
    title
    portfolios {
      ...PortfolioBlock
    }
  }
}
`
