import PropTypes from "prop-types"
import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import DarkModeToggle from "../components/DarkModeToggle"
import Container from "./Container"
import Logo from "./Logo"

const Header = ({ themeColours }) => {

  const { site } = useStaticQuery(graphql`
    query {
      site {
        data: siteMetadata {
          menu {
            name
            to
            hideInMainMenu
          }
        }
      }
    }
  `)

  return (
    <Container>
      <div className="flex justify-between items-center space-x-3 sm:space-x-6">

        <Link to="/" title={`Go to homepage`}>
          <div className="w-12 h-12 lg:-my-3 lg:w-18 lg:h-18">
            <Logo themeColours={themeColours} />
          </div>
        </Link>

        <div className="flex flex-grow justify-center sm:justify-end items-center space-x-4">
          <nav className="main-nav flex-grow text-right ">
            {site.data.menu.map((link, key) => (
              link.hideInMainMenu !== true &&
              <Link
                key={`menu_desktop_link${key}`}
                className="main-nav-link"
                activeClassName="main-nav-link-active"
                to={link.to}
                partiallyActive={true}
              >
                {link.name}
              </Link>
            ))}
          </nav>

        </div>

        <div className="flex-grow-0">
          <DarkModeToggle></DarkModeToggle>
        </div>

      </div>
    </Container>
  )
}

Header.propTypes = {
  themeColours: PropTypes.array,
}

export default Header
