import React from "react"
import Container from "./Container"

const Hero = () => (
  <Container>
    <h1 className="text-3xl sm:text-4xl md:text-5xl tracking-tight md:leading-tight mb-10 max-w-2xl">
      Ben Stevens is a digital product designer based in the UK
    </h1>
    <div className="text-lg sm:text-xl max-w-2xl sm:leading-relaxed">
      <p className="mb-5">
        I help businesses design and launch new digital products.
      </p>
      <p className="mb-5">
        I've worked with{" "}
        <span role="img" aria-label="graduation cap">🎓</span>&nbsp;London School of Economics,{" "}
        <span role="img" aria-label="classical building">🏛</span>&nbsp;V&amp;A&nbsp;Museum,
        <span role="img" aria-label="tropical drink">🍹</span>&nbsp;Honestly Good Smoothie Co, and{" "}
        <span role="img" aria-label="rainbow">🌈</span>&nbsp;Paperchase
      </p>
      <p className="">
        Currently working with{" "}
        <span role="img" aria-label="briefcase">💼</span>&nbsp;YunoJuno
      </p>
    </div>
  </Container>
)

export default Hero
