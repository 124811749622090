import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Card = props => {
  const { name, slug, summary, thumbnail } = props
  const image = getImage(thumbnail)

  return (
      <div className="bg-white dark:bg-gray-dark h-full shadow-lg rounded-2xl overflow-hidden transform hover:scale-102 transition duration-200 ease-in-out group">
        <Link to={`/${slug}/`}>
          <div className="">
            <GatsbyImage
              image={image}
              alt={name}
              backgroundColor="rgba(128,128,128,0.15)" />
          </div>
          <div className="p-4 sm:p-5">
            <h1 className="text-xl xl:text-2xl">{name}</h1>
            <p className="text-sm sm:text-base">{summary}</p>
          </div>
        </Link>
      </div>
    );
}

Card.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  thumbnail: PropTypes.object,
}

export default Card

export const query = graphql`fragment PortfolioCard on ContentfulPortfolio {
  id
  name
  slug
  order
  thumbnail {
    gatsbyImageData(width: 397, height: 298, quality: 85, placeholder: NONE, cropFocus: CENTER, layout: CONSTRAINED)
  }
  summary
  featured
}
`
