import { graphql } from "gatsby"
import React from "react"
import Cards from "../components/Cards"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import CTABlock from "../components/CTABlock"
import Title from "../components/Title"
import Footer from "../components/Footer"
import Container from "../components/Container"

const WorkPage = ({ data }) => {
  const featured = data.portfolio.nodes.filter(item => item.featured === true)
  const notFeatured = data.portfolio.nodes.filter(item => item.featured !== true)
  return (
    <Layout>
      <SiteMetadata title="Work" description="" />

      <Container>
        <Title title="Work" />
        {data.portfolio && data.portfolio.nodes.length > 0 ? (
          <div>
            <Cards items={featured} wide={true} />
            <h2 className="text-2xl md:text-4xl mt-20 mb-10 tracking-tight">Other projects</h2>
            <Cards items={notFeatured} columns={3} />
          </div>
        ) : (
          <div className="container">No projects found.</div>
        )}
      </Container>

      <CTABlock />

      <Footer />

    </Layout>
  )
}

export default WorkPage

export const query = graphql`
  query WorkQuery {
    portfolio: allContentfulPortfolio(sort: {fields: order, order: ASC} filter: {create: {eq: true}}) {
      nodes {
        ...PortfolioCardWide
      }
    }
  }
`
