import { graphql } from "gatsby"
import React from "react"
import Cards from "../components/Cards"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import CTABlock from "../components/CTABlock"
import Title from "../components/Title"
import Footer from "../components/Footer"
import Container from "../components/Container"


const BlogPage = ({ data }) => {

  return (
    <Layout>
      <SiteMetadata title="Blog" description="" />

      <Container>
        <Title title="Blog" />
        {data.posts && data.posts.nodes.length > 0 ? (
          <Cards items={data.posts.nodes} isBlog={true} />
        ) : (
          <div className="container">No posts found.</div>
        )}
        <p className="my-8 text-center leading-relaxed">Not much here yet. <br />Follow <a href={data.site.meta.links.twitter} rel="noopener noreferrer" target="_blank" className="ext-link">@BenStevens</a> on Twitter for more updates</p>
      </Container>

      <CTABlock />

      <Footer />
      
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  query BlogQuery {
    posts: allContentfulPost {
      nodes {
        ...PostCard
      }
    }
    site {
      meta: siteMetadata {
        links {
          twitter
        }
      }
    }
  }
`
