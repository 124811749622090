import { graphql } from "gatsby"
import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import Grid from "../components/Grid"
import Title from "../components/Title"
import Footer from "../components/Footer"
import Container from "../components/Container"

const ArchivePage = ({ data }) => {
  return (
    <Layout>
      <SiteMetadata title="Archive" description="" />

      <Container>
        <Title title="Archive" />
        {data.archive && data.archive.nodes.length > 0 ? (
          <Grid items={data.archive.nodes} />
        ) : (
          <div className="container">No archived items found.</div>
        )}
      </Container>

      <Footer />

    </Layout>
  )
}

export default ArchivePage

export const query = graphql`
  query ArchiveQuery {
    archive: allContentfulArchive(sort: {fields: publishedDate, order: DESC}) {
      nodes {
        ...ArchiveCard
      }
    }
  }
`
