import { graphql } from "gatsby";
import React from "react"
import SiteMetadata from "../components/SiteMetadata"
import Layout from "../layouts/Layout"
import CTABlock from "../components/CTABlock";
import RichTextBlock from "../components/RichTextBlock";
import Breadcrumbs from "../components/Breadcrumbs";
import Footer from "../components/Footer";
import Container from "../components/Container";
import Title from "../components/Title";

const PostTemplate = ({ data }) => {
  const {
    description,
    title,
    related,
    summary,
    thumbnail,
  } = data.item

  return (
    <Layout>
      <SiteMetadata
        title={title}
        description={summary}
        image={thumbnail && thumbnail.file.url}
      />
      <article>
        <Container>
          <div className="flex justify-center">
            <div className="w-full md:w-4/5 lg:w-2/3">
              <Breadcrumbs name="Blog" link="/blog/" />
              <Title title={title} noMargin={true} />
            </div>
          </div>
        </Container>

        {description && (
          <Container classes="md:pt-0 lg:pt-0 xl:pt-0">
            <RichTextBlock description={description} />
          </Container>
        )}

      </article>

      {related && (
        <aside className="bg-gray-light dark:bg-gray py-12 lg:py-16">
          <div className="container">
            <h5 className="text-2xl sm:text-3xl leading-tight font-extrabold tracking-tight mb-8">
              You might also like
            </h5>
          </div>
        </aside>
      )}

      <CTABlock />
      <Footer />
    </Layout>
  )
}

export default PostTemplate

export const query = graphql`
  query PostQuery($slug: String!) {
    item: contentfulPost(slug: { eq: $slug }) {
      description {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            title
            description
            gatsbyImageData(width: 720, quality: 85, placeholder: DOMINANT_COLOR)
          }
        }
      }
      title
      related {
        ...PostCard
      }
      summary
      category
      thumbnail {
        file {
          url
        }
      }
    }
  }
`
