import React, { useEffect, useState } from "react"
import { motion, useAnimation, useMotionValue, useTransform } from "framer-motion"
import PropTypes from "prop-types"

const Logo = ({ tempo, themeColours }) => {

  const controls = useAnimation();

  const [loopCount, setLoopCount] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  const initial = [30, 42, 18, 30, 12, 42, 18];

  const h1 = useMotionValue(initial[0]);
  const h2 = useTransform(h1, value => 72 - value);
  const h3 = useMotionValue(initial[2]);
  const h4 = useTransform(h3, value => 12 + value);
  const h5 = useMotionValue(initial[4]);
  const h6 = useTransform(h3, value => 12 + value);
  const h7 = useTransform(h6, value => 60 - value);

  const roundRandom = (lower, upper, roundTo) => {
    var a = lower + ((upper - lower) * Math.random());
    var b = Math.round(a / roundTo);
    var c = b * roundTo;
    return c
  };

  const colours = themeColours || [
    '#376CF4', 
    '#50E3C2',
    '#DBDBDB',
    '#F75E70',
    '#252525',
    '#F75E70',
    '#969696',
    '#252525',
    '#376CF4',
    '#DBDBDB',
  ];

  useEffect(() => {

    if (isPaused) {
      controls.start(i => {
        switch (i) {
          case 'c1r1':
            return { height: initial[0] }
          case 'c2r1':
            return { height: initial[2] }
          case 'c2r2':
            return { height: initial[4] }
          default:
            return false;
        }
      });
    }

  }, [isPaused]);

  useEffect(() => {

    let interval = null;

    interval = setInterval(() => {
      if (!isPaused) {
        controls.start(i => {
          switch (i) {
            case 'c1r1':
              return { height: roundRandom(12, 60, 6) }
            case 'c2r1':
              return { height: roundRandom(12, 36, 6) }
            case 'c2r2':
              return { height: roundRandom(6, 12, 6) }
            //case 'c2r3':
            //return { height: 0 + (Math.random() * 12) }
            default:
              return false;
          }
        })
        setLoopCount(c => c + 1)
      }
    }, tempo);

    return () => clearInterval(interval);

  }, [loopCount, isPaused, controls]);

  return (
    <motion.svg
      width="100%"
      height="100%"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onHoverStart={() => setIsPaused(true)}
      onHoverEnd={() => setIsPaused(false)}>

      <path d="M39 0 A 21 21 270 0 1 39 42Z" fill={colours[6]} />
      <path d="M39 30 A 21 21 270 0 1 39 72Z" fill={colours[8]} />
      <path d=" M 39 42 A 9 9 270 0 1 39 60Z" fill={colours[9]} />
      <path d=" M 39 12 A 9 9 270 0 1 39 30Z" fill={colours[7]} />
      <motion.rect
        x="0"
        y={h1}
        width="39"
        height={h2}
        fill={colours[1]}
        id="c1r2" />
      <motion.rect
        x="0"
        y="0"
        width="39"
        height={h1}
        fill={colours[0]}
        animate={controls}
        custom={'c1r1'}
        id="c1r1"
      />
      <rect
        x="12"
        y="60"
        width="27"
        height="12"
        fill={colours[5]} />
      <motion.rect
        x="12"
        y={h6}
        width="27"
        height={h7}
        fill={colours[4]}
        animate={controls}
        custom={'c2r3'}
        id="c2r3" />
      <motion.rect
        x="12"
        y={h4}
        width="27"
        height={h5}
        fill={colours[3]}
        animate={controls}
        custom={'c2r2'}
        id="c2r2" />
      <motion.rect
        x="12"
        y="12"
        width="27"
        height={h3}
        fill={colours[2]}
        animate={controls}
        custom={'c2r1'}
        id="c2r1" />
    </motion.svg>
  )
}

Logo.propTypes = {
  tempo: PropTypes.number,
  themeColours: PropTypes.array,
}

Logo.defaultProps = {
  tempo: 1250,
  themeColours: null
}

export default Logo