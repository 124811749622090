import React, { useState } from "react"
import { graphql } from "gatsby"
import Container from "./Container"
import RichTextBlock from "./RichTextBlock"
import classNames from "classnames"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import "intersection-observer"
import Observer from "@researchgate/react-intersection-observer"

const PortfolioBlock = props => {
  const { name, shortDescription, gallery, index } = props
  const [inView, setInView] = useState();
  const handleIntersection = (event, unobserve) => {
    if (event.isIntersecting) {
      unobserve();
    }
    setInView(event.isIntersecting);
  }
  const options = {
    onChange: handleIntersection,
    rootMargin: '0px 0px',
  };
  return (
    <Observer {...options}>
      <div className={classNames("transform transition-all duration-1000 ease-out", { "translate-y-40 opacity-0" : !inView, "translate-y-0 opacity-100" : inView})}>
      <Container classes="text-xl lg:text-2xl" fullWidth={true}>
        <div className="flex flex-wrap justify-center items-start lg:items-center -m-3 lg:-mx-8">
          <div className="px-3 lg:px-8 w-auto md:w-2/3 lg:w-1/2">
            <div className="bg-white dark:bg-gray-dark h-full">
              {gallery && gallery.length >= 1 && (
                <GatsbyImage
                  image={getImage(gallery[0])}
                  alt={name}
                  className=""
                  backgroundColor="rgba(128,128,128,0.15)" />
              )}
            </div>
          </div>
          <div className={classNames("px-3 py-8 md:py-0 lg:p-8 w-auto md:w-1/3 lg:w-1/2", { "lg:order-first lg:flex lg:justify-end": index % 2 === 1 })}>
            <div className="max-w-2xl">
              <RichTextBlock description={shortDescription} naked={true} />
            </div>
          </div>
        </div>
      </Container>
      </div>
    </Observer>
  )
}

export default PortfolioBlock

export const query = graphql`fragment PortfolioBlock on ContentfulPortfolio {
  id
  name
  shortDescription {
    raw
  }
  gallery {
    id
    gatsbyImageData(width: 1200, height: 960, quality: 85, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
    title
  }
}
`
