import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import Footer from "../components/Footer";
import Title from "../components/Title";
import FootprintTable from "../components/FootprintTable";
import Container from "../components/Container";

const SustainabilityPage = ({ data }) => (
  <Layout>
    <SiteMetadata title="Sustainability" description="" />

    <article>
      <Container>
        <div className="flex justify-center">
          <div className="w-full md:w-4/5 lg:w-2/3">
            <Title title={`Sustainability <span role="img" aria-label="globe">🌍</span>`} noMargin={true} />
          </div>
        </div>
      </Container>
      <Container>
        <div className="flex justify-center">
          <div className="w-full md:w-4/5 lg:w-2/3">
            <div className="max-w-prose text-lg lg:text-xl leading-relaxed lg:leading-relaxed">
              <p className="lead">The things we eat, the items we buy, how we choose to get around, and the services we use all have a social and environmental impact. Making sustainable choices now will shape a better future for those that follow.</p>

              <p>The way we build websites should also be sustainable. Each web page we publish requires a certain amount of energy to serve, and this energy has a carbon footprint associated with it. &lsquo;Heavy&rsquo; pages served from fossil fuel-powered data centres produce more CO<sub>2</sub>, whereas &lsquo;light&rsquo; pages served from data centres powered by renewable energy produce much less CO<sub>2</sub>.</p>


              {data.pages && data.pages.nodes.length > 0 && (
                <>
                  <FootprintTable items={data.pages.nodes} caption="BenStevens.co.uk web page carbon footprint data from websitecarbon.com" />
                </>
              )}

              <p>For small websites such as this, the impact of these carbon emissions is negligible. But for large websites that receive millions of hits every day the numbers are significant; a web page with a carbon footprint of just 0.16g per view and 10 million monthly visitors would produce the equivalent of <strong>19,408.59kg of CO<sub>2</sub></strong>, about as much CO<sub>2</sub> as boiling water for 2,629,890 cups of tea. [<a href="https://www.websitecarbon.com" className="ext-link" title="Website Carbon Calculator" target="_blank" rel="noreferrer noopener">Source</a>]</p>

              <p>Therefore in accordance with the <a href="https://www.sustainablewebmanifesto.com" className="ext-link" target="_blank" rel="noreferrer noopener">Sustainable Web Manifesto</a> I pledge to do the following:</p>

              <ul>
                <li>Ensure the services I provide and use will be powered by renewable energy</li>
                <li>Ensure the products and services I provide will use the least amount of energy and material resources possible</li>
                <li>Ensure the products and services I provide will be accessible, allow for the open exchange of information, and allow users to control their data</li>
                <li>Ensure the products and services I provide will not mislead or exploit users in their design or content</li>
                <li>Ensure the products and services I provide will support an economy that nourishes people and planet</li>
                <li>Ensure the products and services I provide will function in the times and places where people need them most</li>
              </ul>

              <p className="mt-16">If you want to discuss website sustainability or share your ideas then please <Link to="/contact/" className="link whitespace-nowrap">get in touch</Link></p>
            </div>
          </div>
        </div>
      </Container>
    </article>

    <Footer />

  </Layout>
)

export default SustainabilityPage

export const query = graphql`
  query SustainabilityQuery {
    pages: allContentfulCarbonFootprint(sort: {fields: lastUpdated, order: DESC}) {
        nodes {
          id
          carbonValue
          lastUpdated
          page {
            ... on ContentfulPortfolio {
              id
              name
            }
            ... on ContentfulPost {
              id
              name: title
            }
            ... on ContentfulArchive {
              id
              name
            }
            ... on ContentfulPage {
              id
              name
            }
          }
        }
    }
  }
`
