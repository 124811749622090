import { graphql } from "gatsby";
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import SiteMetadata from "../components/SiteMetadata"
import Layout from "../layouts/Layout"
import RichTextBlock from "../components/RichTextBlock";
import Breadcrumbs from "../components/Breadcrumbs";
import Footer from "../components/Footer";
import Container from "../components/Container";
import Title from "../components/Title";

const ArchiveTemplate = ({ data }) => {
  const {
    description,
    name,
    thumbnail,
    gallery
  } = data.item

  return (
    <Layout>
      <SiteMetadata
        title={name}
        description=""
        image={thumbnail && thumbnail.file.url}
      />
      <article>
        <Container>
          <div className="flex justify-center">
            <div className="w-full md:w-4/5 lg:w-2/3">
              <Breadcrumbs name="Archive" link="/archive/" />
              <Title title={name} noMargin={true} />
            </div>
          </div>
        </Container>

        {description && (
          <Container classes="md:pt-0 lg:pt-0 xl:pt-0">
            <RichTextBlock description={description} />
          </Container>
        )}

        {gallery && (
          <Container classes="md:pt-0 lg:pt-0 xl:pt-0">
            <div className="flex justify-center">
              <div className="w-full">
                {gallery.length >= 1 && (
                  <GatsbyImage
                    image={gallery[0].gatsbyImageData}
                    alt={name}
                    className="h-screen50 sm:h-auto"
                    backgroundColor="rgba(128,128,128,0.15)" />
                )}
              </div>
            </div>
          </Container>
        )}

      </article>
      <Footer />
    </Layout>
  );
}

export default ArchiveTemplate

export const query = graphql`query ArchiveItemQuery($slug: String!) {
  item: contentfulArchive(slug: {eq: $slug}) {
    description {
      raw
      references {
        ... on ContentfulAsset {
          contentful_id
          __typename
          title
          description
          gatsbyImageData(width: 1024, quality: 85, placeholder: DOMINANT_COLOR)
        }
      }
    }
    name
    gallery {
      id
      gatsbyImageData(width: 1200, height: 900, quality: 85, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
      title
    }
    thumbnail {
      file {
        url
      }
    }
  }
}
`
