import { Link } from "gatsby"
import React from "react"
import Container from "./Container"

const CTABlock = () => (
  <aside className="cta-block">
    <Container classes="py-20 md:py-24 lg:py-48 xl:py-48">
      <p className="text-3xl sm:text-4xl md:text-5xl font-bold leading-tight tracking-tight mb-8 md:mb-12 max-w-2xl">
        Let's work together
      </p>
      <p className="text-lg">
        <Link
        className="button-link"
        to="/contact/"
        title={`Contact`}>Get in touch</Link>
      </p>
    </Container>
  </aside>
)

export default CTABlock
