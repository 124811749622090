import { graphql } from "gatsby"
import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import Footer from "../components/Footer"
import Container from "../components/Container"
import ContactForm from "../components/ContactForm"
import Title from "../components/Title"

const ContactPage = ({ location, data }) => {

  return (
    <Layout>
      <SiteMetadata title="Contact" description="" />
      <Container>
        <Title title={`Get in touch <span role="img" aria-label="call me hand sign">🤙</span>`} noMargin={true} />

        <div className="flex flex-col md:flex-row my-12 space-y-12 md:space-x-10 md:space-y-0">

          <div className="w-full md:w-3/5">

            <ContactForm location={location} />

          </div>

          <div className="w-full md:w-2/5">

            <p className="font-bold text-xl mb-10">
              Email
              <br />
              <a href="mailto:ben@benstevens.co.uk" className="ext-link">ben@benstevens.co.uk</a>
            </p>

            <div className="flex flex-col mb-10 space-y-6">

              <p className="font-bold text-xl">
                Twitter
                <br />
                <a href={data.site.meta.links.twitter} rel="noopener noreferrer" target="_blank" className="ext-link">@BenStevens</a>
              </p>

              <p className="font-bold text-xl">
                Linkedin
                <br />
                <a href={data.site.meta.links.linkedin} rel="noopener noreferrer" target="_blank" className="ext-link">@BenStevens</a>
              </p>

              <p className="font-bold text-xl">
                Instagram
                <br />
                <a href={data.site.meta.links.instagram} rel="noopener noreferrer" target="_blank" className="ext-link">@BenStevens</a>
              </p>

            </div>

          </div>

        </div>

      </Container>

      <Footer />

    </Layout >
  )
}

export default ContactPage

export const query = graphql`{
  author: file(relativePath: {eq: "author.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, height: 480, quality: 85, placeholder: NONE, layout: CONSTRAINED)
    }
  }
  site {
    meta: siteMetadata {
      links {
        twitter
        linkedin
        instagram
      }
    }
  }
}
`
