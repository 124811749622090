import PropTypes from "prop-types"
import React from "react"

const FootprintTableRow = props => {
  const { page, carbonValue, lastUpdated } = props

  return (
      <tr>
        <td>{page.name}</td>
        <td>{carbonValue}</td>
        <td className="whitespace-nowrap">{lastUpdated}</td>
      </tr>
    );
}

FootprintTableRow.propTypes = {
  page: PropTypes.object.isRequired,
  carbonValue: PropTypes.number.isRequired,
  lastUpdated: PropTypes.string.isRequired,
}

export default FootprintTableRow